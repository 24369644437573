<template>
    <footer class="footer-area"  dir="rtl" style=" background: rgb(34,91,52);">
        <div class="container content-footer" >
            <div class="border-bottom" style="border-color: whitesmoke;">
                <div class="row ">
                    <div class=" footer-bloc col-12 col-lg-4 d-flex justify-content-center align-items-start mt-lg-4">
                        <div  style="width: 130px; margin-bottom: auto;margin-top: auto;">
                            <img class="logo" src="../assets/republic-stamp.svg" alt="" >
                        </div>
                    </div>
                    <div class="middle-bloc col-12 col-lg-4  section-contact mt-sm-2 d-flex justify-content-center">
                        <div>
                            <h6 class="text-light ml-50" >{{ $t('contact') }}</h6>
                            <ul class=" section-body mb-30">
                                <li class=" mt-2 text-light">  <font-awesome-icon icon="fa-solid fa-phone" /> &nbsp;&nbsp;<span class="section-contact-info"> الهاتف : 18 25 78 21 (0) 213+</span></li>
                                <li class=" mt-2 text-light"> <font-awesome-icon icon="fa-solid fa-fax" /> &nbsp;&nbsp;<span class="section-contact-info"> الفاكس : 18 25 78 21 (0) 213+</span></li>
                                <li class=" mt-2 contact-email text-light"><a href="mailto:" target="_blank" style="text-decoration: none; color: white"><font-awesome-icon icon="fa-solid fa-envelope" />  &nbsp;&nbsp;<span class="section-contact-info">&nbsp;contact@csj.gov.dz</span></a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class=" footer-bloc col-12 col-lg-4 d-flex justify-content-center align-items-start mt-lg-4">
                        <div  style="width: 130px; margin-bottom: auto;margin-top: auto;">
                            <img class="logo" :src="'../images/logo_csj.ico'" width="140" alt="" >
                        </div>
                    </div>
                </div>
                <div class="text-center social_media">
                    <a href="https://www.facebook.com/AlgeriaHCY/" target="_blank"  rel="noopener noreferrer">
                        <img :src="'../images/icons/facebook.png'" height="24" alt="">
                    </a>
                    <a href="https://www.youtube.com/@AlgeriaHcy-2025/videos" target="_blank"  rel="noopener noreferrer">
                        <img :src="'../images/icons/youtube.png'" height="24" alt="">
                    </a>
                    <a href="https://twitter.com/AlgeriaHCY" target="_blank"  rel="noopener noreferrer">
                        <img :src="'../images/icons/twitter.png'" height="24" alt="">
                    </a>
                    <a href="https://www.instagram.com/Algeria.HCY/" target="_blank"  rel="noopener noreferrer">
                        <img :src="'../images/icons/instagram.png'" height="24" alt="">
                    </a>
                    <a href="https://www.linkedin.com/company/algeriahcy/" target="_blank"  rel="noopener noreferrer">
                        <img :src="'../images/icons/linkedin.png'" height="24" alt="">
                    </a>
                </div>
            </div>
            <h6 class="text-center pt-1 droit-reseve" style="color: lightgray;"> 2023 ® كل الحقوق محفوظة . المجلس الأعلى للشباب - الجزائر  </h6>
        </div>
    </footer>

</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style>
    .social_media{
        margin-bottom: 10px;
    }
    .social_media img{
        margin-right: 8px;
    }
    .section-body{
        border-right: 4px solid #454d54;
        margin: 30px 0;
    }
    footer{
        padding-top: 20px;
        /*font-family: DroidArabic;*/
        font-size:var(--m-font-size);
        padding-bottom: 20px;
        margin-top: auto;
    }

    .footer-bloc, .middle-bloc{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 991px){
        .middle-bloc{
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    footer .droit-reseve{
        font-size: var(--s-font-size);
    }

    footer ul{
        padding-left: 0;
    }

</style>

